export const BASE_HREF = "https://polymarket.com";
export const ELECTIONS_PAGE_HREF = `${BASE_HREF}/elections`;

export const EMBED_NAME = "polymarket-election-embed"

export const TRUMP_IMAGE_URL = `${BASE_HREF}/images/elections/pres-trump.png`;
export const HARRIS_IMAGE_URL = `${BASE_HREF}/images/elections/pres-kamala.png`;
export const DEMOCRAT_IMAGE_URL = `${BASE_HREF}/images/elections/democrat-icon.png`;
export const REPUBLICAN_IMAGE_URL = `${BASE_HREF}/images/elections/republican-icon.png`;

export const TRUMP_MARKET_SLUG = "will-donald-trump-win-the-2024-us-presidential-election";
export const HARRIS_MARKET_SLUG = "will-kamala-harris-win-the-2024-us-presidential-election";
export const PRESIDENTIAL_EVENT_SLUG = "presidential-election-winner-2024";
export const SENATE_EVENT_SLUG =
    "which-party-will-control-the-us-senate-after-the-2024-election";

export const SUPPORTED_EVENTS = new Set([
    PRESIDENTIAL_EVENT_SLUG,
    SENATE_EVENT_SLUG,
]);

export const ELECTION_EMBED_SLUG_DETAILS_MAP = {
    [PRESIDENTIAL_EVENT_SLUG]: {
        leftMarket: {
            title: "Donald Trump",
            shortTitle: "Trump",
            image: TRUMP_IMAGE_URL,
            slug: TRUMP_MARKET_SLUG,
        },
        rightMarket: {
            title: "Kamala Harris",
            shortTitle: "Harris",
            image: HARRIS_IMAGE_URL,
            slug: HARRIS_MARKET_SLUG,
        }
    },

    [SENATE_EVENT_SLUG]: {
        leftMarket: {
            title: "Republicans",
            shortTitle: "Republicans",
            image: REPUBLICAN_IMAGE_URL,
            slug: SENATE_EVENT_SLUG,
        },
        rightMarket: {
            title: "Democrats",
            shortTitle: "Democrats",
            image: DEMOCRAT_IMAGE_URL,
            slug: SENATE_EVENT_SLUG,
        }
    }
}
