import { css, LitElement, html, CSSResult, PropertyValues } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import loadFonts from "../../../common/loadFonts.js";
import { cssVarStyling } from "../../../common/styles.js";

loadFonts(); /* eagerly load fonts */

@customElement("polymarket-rumble-join-embed")
export class RumbleJoinEmbed extends LitElement {
    static override get styles(): CSSResult {
        return css`
            ${cssVarStyling}

            :host * {
                box-sizing: border-box;
            }

            .container {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: calc(100vw - 4px);
                height: fit-content;
                justify-content: center;
                align-items: center;
                padding: 2px;
            }

            .green-button {
                width: 400px;
                background-color: #7ed321;
                border-radius: 5px;
                padding: 10px 20px;
                font-size: 16px;
                text-align: center;
                color: white;
                text-decoration: none;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
                transition: transform 0.3s ease;
                &:hover {
                    transform: scale(1.1);
                }
            }
        `;
    }

    // TODO - Implement width
    @property({
        type: Number,
        attribute: "width",
        converter: (value, _) => {
            if (typeof value === "string") {
                try {
                    return parseInt(value, 10);
                } catch {
                    /* ignore */
                }
            }
            if (typeof value === "number") return value;
            return 400;
        },
    })
    // @ts-ignore
    private accessor width: number = 400;

    @state()
    private accessor _href: string = "https://polymarket.com";

    constructor() {
        super();
        loadFonts();
    }

    override connectedCallback() {
        super.connectedCallback();
        loadFonts();
    }

    protected override firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        const utmParams = this.getUtmParams();
        this._href = `https://polymarket.com?${utmParams}`;
    }

    private getUtmParams() {
        const utmMedium = "utm_medium=embed";
        const utmCampaign = "&utm_campaign=rumble";
        const _utmSource =
            window.location != window.parent.location && !!document.referrer
                ? document.referrer
                : `${document.location.host}${document.location.pathname}`;
        const utmSource = `&utm_source=${_utmSource}`;

        return `${utmMedium}${utmCampaign}${utmSource}`;
    }
    override render() {
        return html` <div class="container">
            <a
                class="green-button"
                href="${this._href}"
                target="_blank"
                rel="noopener noreferrer"
            >
                Join the world's largest prediction market >>
            </a>
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "polymarket-rumble-join-embed": RumbleJoinEmbed;
    }
    namespace JSX {
        interface IntrinsicElements {
            "polymarket-rumble-join-embed": {};
        }
    }
}

/*
  Define the custom element if it hasn't been defined already.
  This is to prevent errors when using multiple versions of the polymarket-rumble-join-embed on the same page.
*/
if (!customElements.get("polymarket-rumble-join-embed")) {
    customElements.define("polymarket-rumble-join-embed", RumbleJoinEmbed);
}
