import { GAMMA_API_URL } from "./constants.js";
import { Market, Event, RequestParams } from "./types.js";

export const getMarket = async (params: RequestParams): Promise<Market> => {
    const path = params.slug
        ? `markets/slug/${params.slug}`
        : `markets/${params.id}`;
    const response = await fetch(`${GAMMA_API_URL}/${path}`);
    const json = await response.json();
    return {
        ...json,
        outcomes: JSON.parse(json.outcomes),
        outcomePrices: JSON.parse(json.outcomePrices),
        clobTokenIds: json.clobTokenIds ? JSON.parse(json.clobTokenIds) : null,
    };
};

export const getEvent = async (params: RequestParams): Promise<Event> => {
    const path = params.slug
        ? `events/slug/${params.slug}`
        : `events/${params.id}`;
    const response = await fetch(`${GAMMA_API_URL}/${path}`);
    const json = await response.json();
    return {
        ...json,
        markets:
            json.markets?.map((market: any) => ({
                ...market,
                outcomes: JSON.parse(market.outcomes),
                outcomePrices: JSON.parse(market.outcomePrices),
                clobTokenIds: market.clobTokenIds
                    ? JSON.parse(market.clobTokenIds)
                    : null,
            })) ?? [],
    };
};
