import { css } from "lit";

export const cssVarStyling = css`
:host {
    --polymarket-color-white: #ffffff;
    --polymarket-color-black: #000000;
    --polymarket-color-gray-1: #333333;
    --polymarket-color-gray-2: #4f4f4f;
    --polymarket-color-gray-3: #828282;
    --polymarket-color-gray-4: #bdbdbd;
    --polymarket-color-gray-5: #e0e0e0;
    --polymarket-color-gray-6: #f2f2f2;

    --polymarket-color-red: #e64800;
    --polymarket-color-green: #00b955;
    --polymarket-color-neutral: #858a98;

    --polymarket-color-election-red-1: #fcf2f2;
    --polymarket-color-election-blue-1: #eff5f9;

    --polymarket-radii-sm: 4px;
    --polymarket-radii-md: 8px;
    --polymarket-radii-lg: 12px;
    --polymarket-radii-xl: 16px;
    --polymarket-radii-full: 50%;

    --polymarket-space-1: 4px;
    --polymarket-space-2: 8px;
    --polymarket-space-3: 12px;
    --polymarket-space-4: 16px;
    --polymarket-space-5: 20px;

    --polymarket-font-size-xs: 10px;
    --polymarket-font-size-sm: 11px;
    --polymarket-font-size-md: 13px;
    --polymarket-font-size-lg: 15px;
    --polymarket-font-size-xl: 18px;

    --polymarket-line-height-sm: 13px;
    --polymarket-line-height-md: 17px;
    --polymarket-line-height-lg: 19px;
    --polymarket-line-height-xl: 21px;

    --polymarket-font-weight-normal: 400;
    --polymarket-font-weight-medium: 500;
    --polymarket-font-weight-semi: 600;
    --polymarket-font-weight-bold: 700;

    display: block;
    width: 100%;
    height: 100%;

    font-family: "Open Sauce Sans", sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:host([theme="dark"]) {
    --polymarket-color-white: #1d2b39;
    --polymarket-color-black: #ffffff;
    --polymarket-color-gray-1: #f2f2f2;
    --polymarket-color-gray-2: #858d92;
    --polymarket-color-gray-3: #858d92;
    --polymarket-color-gray-4: #425464;
    --polymarket-color-gray-5: #344452;
    --polymarket-color-gray-6: #2c3f4f;
}
`;